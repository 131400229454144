import { createTheme } from "@mui/material/styles";
// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from "@mui/material/styles";

const teal = "#94BBB4";
const pink = "#9A6372";
const lightTeal = "#F0F5EE";
const yellow = "#F7CE5B";
const gunMetal = "#272D2D";

const theme = createTheme({
  palette: {
    primary: {
      main: teal,
    },
    secondary: {
      main: pink,
    },
    lightTeal: {
      main: lightTeal,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#fff #babac0",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#fff",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            backgroundColor: "#babac0",
            borderRadius: "16px",
            border: "4px solid #fff",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#babac0",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#babac0",
            },
        },
      },
    },
  },
});

// declare module "@mui/material/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends Theme {}
// }

declare module "@mui/material/styles" {
  interface Palette {
    lightTeal: Palette["secondary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    lightTeal?: PaletteOptions["secondary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    lightTeal: true;
  }
}

export default theme;
