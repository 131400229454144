import React from "react";
import { Theme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
//import theme from "../theme";

interface TopLayoutProps {
  children: React.ReactNode;
  theme: Theme;
}

export default function TopLayout({ children, theme }: TopLayoutProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
