exports.components = {
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-faqs-tsx": () => import("./../../../src/pages/about/faqs.tsx" /* webpackChunkName: "component---src-pages-about-faqs-tsx" */),
  "component---src-pages-about-our-team-tsx": () => import("./../../../src/pages/about/our-team.tsx" /* webpackChunkName: "component---src-pages-about-our-team-tsx" */),
  "component---src-pages-about-story-tsx": () => import("./../../../src/pages/about/story.tsx" /* webpackChunkName: "component---src-pages-about-story-tsx" */),
  "component---src-pages-blog-strapi-category-slug-tsx": () => import("./../../../src/pages/blog/{StrapiCategory.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-category-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-get-involved-community-network-tsx": () => import("./../../../src/pages/get-involved/community-network.tsx" /* webpackChunkName: "component---src-pages-get-involved-community-network-tsx" */),
  "component---src-pages-get-involved-farmers-and-landowners-tsx": () => import("./../../../src/pages/get-involved/farmers-and-landowners.tsx" /* webpackChunkName: "component---src-pages-get-involved-farmers-and-landowners-tsx" */),
  "component---src-pages-get-involved-gardens-and-greenspaces-tsx": () => import("./../../../src/pages/get-involved/gardens-and-greenspaces.tsx" /* webpackChunkName: "component---src-pages-get-involved-gardens-and-greenspaces-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maps-tsx": () => import("./../../../src/pages/maps.tsx" /* webpackChunkName: "component---src-pages-maps-tsx" */),
  "component---src-pages-partner-with-us-tsx": () => import("./../../../src/pages/partner-with-us.tsx" /* webpackChunkName: "component---src-pages-partner-with-us-tsx" */),
  "component---src-pages-pledge-community-groups-tsx": () => import("./../../../src/pages/pledge/community-groups.tsx" /* webpackChunkName: "component---src-pages-pledge-community-groups-tsx" */),
  "component---src-pages-pledge-farmers-and-landowners-tsx": () => import("./../../../src/pages/pledge/farmers-and-landowners.tsx" /* webpackChunkName: "component---src-pages-pledge-farmers-and-landowners-tsx" */),
  "component---src-pages-pledge-gardens-and-greenspaces-tsx": () => import("./../../../src/pages/pledge/gardens-and-greenspaces.tsx" /* webpackChunkName: "component---src-pages-pledge-gardens-and-greenspaces-tsx" */),
  "component---src-pages-pledge-land-app-instructions-tsx": () => import("./../../../src/pages/pledge/land-app-instructions.tsx" /* webpackChunkName: "component---src-pages-pledge-land-app-instructions-tsx" */),
  "component---src-pages-pledge-partnership-tsx": () => import("./../../../src/pages/pledge/partnership.tsx" /* webpackChunkName: "component---src-pages-pledge-partnership-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-map-tsx": () => import("./../../../src/templates/map.tsx" /* webpackChunkName: "component---src-templates-map-tsx" */)
}

